.superRoot {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.root {
  display: flex;
  width: 100%;
}

.mandatoryP {
  color: red;
  font-size: 0.75em;
}

.input_area {
  width: 50%;
  height: auto;
  min-height: 100vh;
  padding: 20px;
  outline: none;
  display: flex;
  flex-direction: column;
}

.output_area {
  width: 50%;
  height: auto;
  min-height: 100vh;
  padding: 20px;
  margin-bottom: 16px;
  outline: none;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
}

.inputTextArea {
  height: 100%;
  width: 100%;
  margin-top: 16px;
}

.outMarkDown {
  height: 100%;
  width: 100%;
}

.editorTitle {
  width: 100%;
  display: inline-block;
}

.smallImgCard {
  width: 64px;
  height: 64px;
  margin: 8px;
}

.smallimg {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
