.prodileRootCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 32px;
  padding: 16px;
}

.profileDiv {
  display: inline-flex;
  margin-top: 16px;
}

.nameText {
  font-weight: 800;
  font-size: 2.5rem;
  margin: 0;
}

.bioText {
  font-weight: 600;
  font-size: 1.2rem;
  margin: 0;
  margin-top: 8px;
}

.detailsDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.profileItems {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.privacyButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
