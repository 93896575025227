.rootDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.isLoadingDiv {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
}

.blogRootCard {
  margin: 16px;
  margin-right: 8px;
  width: 100%;
  flex: 6;
}

.sideDiv {
  width: 100%;
  margin: 8px;
  margin-top: 16px;
  padding: 8px;
  flex: 1;
}

.cardHeaderMedia {
  height: 300px;
  width: 100%;
}

.titleText {
  font-weight: 800;
  font-size: 2.2rem;
}

.cardContent {
  margin: 16px;
}

.blogMD {
  padding: 0px 16px;
}

.authTextHolder {
  margin-left: 8px;
}

.namePara {
  font-weight: bold;
  text-transform: uppercase;
  font-size: medium;
  padding: 0;
  margin: 0;
}

.postedPara {
  font-weight: 300;
  font-size: small;
  color: gray;
  padding: 0;
  margin: 0;
}

.tags {
  margin-top: 16px;
}
