.authorHolderDiv {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.avatarBackground {
  display: flex;
  justify-content: center;
  align-items: center;
}

.authorHolderDiv {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.avatarBackground {
  display: block;
  justify-content: center;
  align-items: center;
  width: 74px;
  height: 74px;
  border-radius: 200px;
  background: white;
  position: absolute;
  margin-top: -4px;
}

.avatar {
  width: 64px;
  height: 64px;
}

.authCard {
  border-radius: 16px;
  box-shadow: 4px 4px 4px black;
  background-color: #d1fffb;
  width: 300px;
  padding: 32px 16px;
}

.checkoutBtnContainer {
  margin-top: 8px;
  justify-content: center;
  align-items: center;
}

.checkoutBtn {
  width: 100%;
}

.headingP {
  font-weight: bolder;
  margin: 0px;
}

.bodyP {
  font-weight: lighter;
  margin-top: -2px;
}

.linkIconContainer {
  display: inline-flex;
}

.linkIcon {
  margin: 4px;
}
