.App {
  display: flex;
  width: 100%;
  flex-direction: column;
}

body, html { 
  font-family:  "Roboto", sans-serif;

  font-optical-sizing: auto;
}

h1,h2,h3,h4,h5,h6{
  font-family: "Roboto Mono", monospace;
  font-optical-sizing: auto;
}

table {
  padding: 0;
}

table tr {
  border-top: 1px solid #cccccc;
  background-color: white;
  margin: 0;
  padding: 0;
}

table tr:nth-child(2n) {
  background-color: #f8f8f8;
}

table tr th {
  font-weight: bold;
  border: 1px solid #cccccc;
  text-align: left;
  margin: 0;
  padding: 6px 13px;
}

table tr td {
  border: 1px solid #cccccc;
  text-align: left;
  margin: 0;
  padding: 6px 13px;
}

table tr th :first-child,
table tr td :first-child {
  margin-top: 0;
}

table tr th :last-child,
table tr td :last-child {
  margin-bottom: 0;
}
