.highlightRoot {
  display: inline;
  position: relative;
}

.inlineCode {
  display: inline;
  padding: 0 4px;
}

.codeBlock {
  color: white;
}

.iconBtn {
  width: 28px;
  height: 28px;
  border-radius: 4px;
  border: 1px solid white;
  position: absolute;
  top: 4px;
  right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconBtnIcon {
  width: 16px;
  height: 16px;
  color: white;
  position: relative;
}
