#root {
  min-height: 450px;
}

.homeCardTitle {
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.readTimeP {
  font-weight: lighter;
  font-size: small;
  margin: 0;
  margin-top: 8px;
}

.authNameP {
  font-size: small;
  margin: 0;
  margin-left: 4px;
}

.authorDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
}
