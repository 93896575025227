.card {
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;
}
.card-expanded {
    width: 100%;
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;
}