.rootMPBasic {
  padding: 8px;
  margin-top: 8px;
  margin: 8px;
}

.authDiv {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.authName {
  margin: 0;
  font-weight: 600;
  font-size: smaller;
  margin-left: 8px;
}

.mpBasicItemBody {
  display: flex;
  flex-direction: row;
}

.blogTitleText {
  font-weight: bold;
  font-size: 0.9em;
  margin-top: 2px;
}

.blogAvatarContainer {
  margin-top: 4px;
}

.mpBasicChip {
  margin-left: 2px;
}
