.morePageRoot {
  margin-top: 16px;
}

.moreTitle {
  margin-left: 8px;
  font-weight: bold;
}

.isLoadingDivMorePages {
  max-width: 100px;
}
